<template>
  <ListFrame
    :immediateInit="false"
    :getPageFn="getPage"
    :viewConf="{
      showLvl: true,
      showTitle: true,
      showContent: true,
      showReorder: false,
      shelfOperateAble:
        roleCode === 6 ? { offshelfFn: downShelf, onshelfFn: upShelf } : false,
    }"
    :customedParams="selfCondition"
  >
    <template slot-scope="{ item, requestFn }">
      <TaskItem
        v-if="'cnIdiomComb' === item.contentType"
        :idomComb="item"
        style="margin-bottom: 1em; flex: 1"
      >
        <template v-slot:after-tags-editor>
          <span class="status-span active" v-if="item.status == 3">已上架</span>
          <span class="status-span" v-else>
            已下架
            <span style="padding-left: 1em">下架原因：{{ item.remark }}</span>
          </span>
        </template>

        <template v-slot:progress>
          <ProcessSteps
            style="margin: 6px 0; font-size: 16px; width: 700px"
            :taskName="9"
          />
        </template>
      </TaskItem>
      <FlyingShowItem
        v-else
        :data="item"
        type="0"
        :published="true"
        @refresh="requestFn"
      >
        <template v-slot:progress>
          <ProcessSteps
            style="margin: 6px 0; font-size: 16px; width: 700px"
            :taskName="9"
          />
        </template>
      </FlyingShowItem>
    </template>
    <template #conditions="{ requestFn }">
      <FirstCategory
        classification="game"
        class="typeSelect"
        :hiddenWhole="true"
        v-model="selfCondition.contentType"
        @input="requestFn"
      />
    </template>
  </ListFrame>
</template>

<script>
import TaskItem from "./TaskItem";
import FlyingShowItem from "./FlyingShowItem";
import { getPublishedList, downShelf, upShelf } from "./services";

export default {
  components: { FlyingShowItem, TaskItem },
  data() {
    return {
      selfCondition: { contentType: "cnIdiomComb" },
    };
  },
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
  },

  methods: {
    getPage(params) {
      return getPublishedList(params);
    },
    downShelf(list, reason) {
      return downShelf(list, reason);
    },
    upShelf(list) {
      return upShelf(list);
    },
  },
  beforeMount() {},
};
</script>
<style scoped>
.typeSelect {
  width: 200px;   
}
</style>
