var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ListFrame',{attrs:{"immediateInit":false,"getPageFn":_vm.getPage,"viewConf":{
    showLvl: true,
    showTitle: true,
    showContent: true,
    showReorder: false,
    shelfOperateAble:
      _vm.roleCode === 6 ? { offshelfFn: _vm.downShelf, onshelfFn: _vm.upShelf } : false,
  },"customedParams":_vm.selfCondition},scopedSlots:_vm._u([{key:"default",fn:function({ item, requestFn }){return [('cnIdiomComb' === item.contentType)?_c('TaskItem',{staticStyle:{"margin-bottom":"1em","flex":"1"},attrs:{"idomComb":item},scopedSlots:_vm._u([{key:"after-tags-editor",fn:function(){return [(item.status == 3)?_c('span',{staticClass:"status-span active"},[_vm._v("已上架")]):_c('span',{staticClass:"status-span"},[_vm._v(" 已下架 "),_c('span',{staticStyle:{"padding-left":"1em"}},[_vm._v("下架原因："+_vm._s(item.remark))])])]},proxy:true},{key:"progress",fn:function(){return [_c('ProcessSteps',{staticStyle:{"margin":"6px 0","font-size":"16px","width":"700px"},attrs:{"taskName":9}})]},proxy:true}],null,true)}):_c('FlyingShowItem',{attrs:{"data":item,"type":"0","published":true},on:{"refresh":requestFn},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('ProcessSteps',{staticStyle:{"margin":"6px 0","font-size":"16px","width":"700px"},attrs:{"taskName":9}})]},proxy:true}],null,true)})]}},{key:"conditions",fn:function({ requestFn }){return [_c('FirstCategory',{staticClass:"typeSelect",attrs:{"classification":"game","hiddenWhole":true},on:{"input":requestFn},model:{value:(_vm.selfCondition.contentType),callback:function ($$v) {_vm.$set(_vm.selfCondition, "contentType", $$v)},expression:"selfCondition.contentType"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }